@charset 'utf-8';
@import '../_env.scss';

@media (min-width: $breakpoint) {
	.site-header {
		min-height: 110px;
		height: auto;
	}
}

.page > .body {
	padding-top: 0;
}

.home-block-index {
	position: relative;
	margin: 1px calc(-1 * (100vw - #{$maxwidth_content} + 120px) / 2);
	width: 100vw;
	background: #000;
	color: #fff;
	overflow: hidden;

	@media (max-width: $maxwidth_content + 40px) {
		margin-left: -80px;
		margin-right: -80px;
		width: $maxwidth_content + 40px;
	}

	@media (max-width: $breakpoint) {
		margin-left: -15px;
		margin-right: -15px;
		text-align: center;
		width: auto;
	}

	.bg {
		display: block;
		width: 100%;
	}

	.container {
		position: absolute;
		top: 0;
		left: 0;
		right: 0;
		bottom: 0;
		display: flex;
		flex-direction: column;
		justify-content: center;
		box-sizing: border-box;
		margin: auto;
		padding: 0 60px;
		width: $maxwidth_content;

		@media (max-width: $breakpoint) {
			padding: 0 15px;
			width: auto;
		}
	}

	.header {
		.title {
			margin: 0;
			padding: 0;
		}

		.supertitle-ja,
		.title-ja,
		.title-en,
		.subtitle-en {
			display: block;
		}

		.supertitle-ja {
			font-size: .625rem;
			line-height: 1.5;
			opacity: .5;
		}

		.title-ja {
			font-size: 1.5rem;
			line-height: 1.5;

			@media (max-width: $breakpoint) {
				font-size: 1rem;
			}
		}

		.title-en {
			font-weight: normal;
			font-size: 3.75rem;
			line-height: 1.25;

			@media (max-width: $breakpoint) {
				font-size: 2rem;
			}
		}

		.subtitle-en {
			font-weight: normal;
			font-size: 1.875rem;
			line-height: 1.25;

			@media (max-width: $breakpoint) {
				font-size: 1.25rem;
				line-height: 1;
			}
		}
	}

	.body {
		.description {
			margin: 30px 0 0;
			padding: 0;
			font-size: 1.125rem;
			line-height: 1.55555555556;

			@media (max-width: $breakpoint) {
				font-size: .75rem;
				line-height: 1.4;
			}
		}

		.link {
			a {
				position: relative;
				display: block;
				box-sizing: border-box;
				margin: 80px auto 0;
				padding: 15px;
				border: 1px solid rgba(255, 255, 255, .5);
				border-radius: 6px;
				max-width: 220px;
				background: rgba(0, 0, 0, .3);
				color: inherit;
				font-weight: 700;
				font-size: .875rem;
				line-height: 1.42857142857;
				text-decoration: none;
				opacity: 1;
				overflow: hidden;

				@media (max-width: #{$maxwidth_content + 20px}) {
					margin-top: 40px;
				}

				@media (max-width: $breakpoint) {
					margin-top: 30px;
					padding: 6px;
					font-size: .75rem;
					line-height: 1.5;
					background: rgba(0, 0, 0, .2);
				}

				&:after {
					content: "";
					display: inline-block;
					position: absolute;
					top: 0;
					right: 20px;
					bottom: 0;
					margin: auto;
					width: 6px;
					height: 8px;
					background: url(../../img/icon_link_white.png) 0 0 no-repeat;
					background-size: 100%;
				}

				&:before {
					content: "";
					position: absolute;
					top: 0;
					left: -100%;
					width: 100%;
					height: 100%;
					background: rgba(255, 255, 255, 0.3);
					transition: all .2s ease-in-out;
					opacity: 0;
				}

				&:hover {
					opacity: 1;

					&:before {
						left: 0%;
						opacity: 1;
					}
				}
			}
		}
	}
}



.home-news {
	margin: 70px 0 0;

	.h4 {
		font-size: 1.5rem;
	}
}



.table-news {
	margin-bottom: 100px;
}


