@charset 'utf-8';

$breakpoint: 767.01px;

$color_ci: #666;
$color_attention: #5d7794;

$color_bg: #fff;
$color_button: #999;
$color_text: #333;
$color_text_weak: #999;
$color_mask: rgba(191, 191, 191, 0.6);
$color_link: #333;

$fontsize_exlarge: 2.25rem;
$fontsize_large: 1.5rem;
$fontsize_normal: 1rem;
$fontsize_small: .875rem;
$fontsize_exsmall: .75rem;

$lineheight_wide: 2.75em;
$lineheight_normal: 2.25em;
$lineheight_thin: 1.75em;

$maxwidth_content: 940px;

$duration_normal: .4s;

%clearfix {
	&:after {
		content: "";
		clear: both;
		display: block;
	}
}
